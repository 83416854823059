.pp-language {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.pp-language.active {
    text-decoration: underline !important;
    text-underline-offset: 6px;
}
