/* Open calendar on date input click */
input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

/* Active service button styles */
.list-group-item.active {
    background-color: #ffffff !important;
    color: var(--carup-primary-blue);
    border-color: var(--carup-primary-blue) !important;
    border-left-width: 5px;
}

.container {
    margin: 0 auto;
}

hr {
    margin: 30px auto;
}

h2 {
    text-align: center;
}

.href-underline {
    text-decoration: underline !important;
}

/**
* Stepper styles
**/
.stepper-wrapper * {
    box-sizing: border-box;
}
.stepper-wrapper .stepper-head {
    display: flex;
    position: relative;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.stepper-wrapper .stepper-head.inline-stepper-head .stepper-step:after {
    top: 50%;
    transform: translateY(-50%);
}
.stepper-wrapper .stepper-head.inline-stepper-head .stepper-indicator,
.stepper-wrapper .stepper-head.inline-stepper-head .stepper-label {
    display: inline-block;
    vertical-align: middle;
}
.stepper-wrapper .stepper-head.inline-stepper-head .stepper-label {
    text-align: left;
    padding: 0 0.5rem;
    background: #fff;
}
.stepper-wrapper .stepper-head.vertical-stepper-head {
    flex-direction: column;
    width: auto;
}
.stepper-wrapper .stepper-head.vertical-stepper-head .stepper-step {
    display: flex;
    align-items: center;
    text-align: start;
}
.stepper-wrapper .stepper-head.vertical-stepper-head .stepper-step:after {
    content: " ";
    position: absolute;
    left: 1.4rem;
    right: auto;
    top: 2rem;
    bottom: -0.5rem;
    width: 0;
    height: auto;
    border-left: 0.125rem solid #e3e8ec;
}
.stepper-wrapper
    .stepper-head.vertical-stepper-head
    .stepper-step.rightToLeft:after {
    right: 1.5rem;
    left: auto;
}
.stepper-wrapper .stepper-head.vertical-stepper-head .stepper-label {
    -webkit-padding-start: 1rem;
    padding-inline-start: 1rem;
}
.stepper-wrapper .stepper-head.vertical-stepper-head + .stepper-body {
    -webkit-margin-start: 3.5rem;
    margin-inline-start: 3.5rem;
}
.stepper-wrapper .stepper-head .stepper-step {
    position: relative;
    text-align: center;
    padding: 0.5rem;
    flex-basis: 100%;
}
.stepper-wrapper .stepper-head .stepper-step.rightToLeft:after {
    left: -50%;
}
.stepper-wrapper
    .stepper-head
    .stepper-step.is-complete
    .stepper-indicator-info {
    border-color: #4caf50;
    background-color: #4caf50;
    cursor: pointer;
}
.stepper-wrapper .stepper-head .stepper-step.is-complete .stepper-label {
    color: #4caf50;
}
.stepper-wrapper .stepper-head .stepper-step.is-active .stepper-indicator-info {
    border-color: #627c90;
    background-color: #627c90;
    cursor: initial;
}
.stepper-wrapper .stepper-head .stepper-step.is-active .stepper-label {
    color: #627c90;
}
.stepper-wrapper
    .stepper-head
    .stepper-step.is-warning
    .stepper-indicator-info {
    border-color: #f1c40f;
    background-color: #f1c40f;
}
.stepper-wrapper .stepper-head .stepper-step.is-warning .stepper-label {
    color: #f1c40f;
}
.stepper-wrapper .stepper-head .stepper-step.is-error .stepper-indicator-info {
    border-color: #e95a4b;
    background-color: #e95a4b;
}
.stepper-wrapper .stepper-head .stepper-step.is-error .stepper-label {
    color: #e95a4b;
}
.stepper-wrapper .stepper-head .stepper-step:after {
    content: " ";
    position: absolute;
    left: 50%;
    top: 1.5rem;
    width: 100%;
    height: 0.125rem;
    background-color: #e3e8ec;
    z-index: 1;
}
.stepper-wrapper .stepper-head .stepper-step:last-child:after {
    display: none;
}
.stepper-wrapper .stepper-head .stepper-indicator {
    position: relative;
    display: block;
    z-index: 2;
}
.stepper-wrapper .stepper-head .stepper-indicator-info {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border: 1px solid #e3e8ec;
    border-radius: 50%;
    background-color: #e3e8ec;
    font-size: 1rem;
    line-height: 2.5rem;
    text-align: center;
    color: #fff;
    z-index: 2;
}
.stepper-wrapper .stepper-head .stepper-indicator-info .stepper-tick {
    width: 14px;
    fill: #ffffff;
}
.stepper-wrapper .stepper-head .stepper-label {
    position: relative;
    display: block;
    margin: 0.5rem 0;
    color: #adb4ba;
    z-index: 2;
}
