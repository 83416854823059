.overlay-coming-soon {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    border-radius: var(--bs-border-radius);
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.accordion {
    transition: 0.4s;
}

.accordion-content-open {
    max-height: 1000px;
    transition: max-height 0.4s ease-in;
    overflow: hidden;
}

.accordion-content-closed {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
}

.accordion-img {
    width: 22px;
    color: var(--carup-primary-blue);
}

[data-toggle="true"] .accordion-img {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
}

.steps {
    color: var(--carup-primary-blue);
}

.packages-table th {
    background: none;
}
.packages-table td {
    background: none;
}

.order-package-active {
    border: solid 2px var(--carup-primary-blue);
}

.package-title {
    font-size: 1rem;
}
.package-select-wrapper {
    margin-left: -15px;
    margin-right: -15px;
    padding: 10px;
    margin-right: 0;
}
.package-select-icon {
    width: 25px;
}

.testimonials {
    display: flex;
    justify-content: center;
    align-items: center;
}

.testimonials-item {
    background: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
