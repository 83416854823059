:root {
    --carup-primary-blue: #1e214e;
    --carup-primary-turqoise: #0fc8e2;
}

a {
    color: var(--carup-primary-blue) !important;
    text-decoration: none !important;
    /* text-decoration-thickness: 0.1px !important; */
}

body {
    font-family: "DejaVuSans", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Background bubbles animation */
.bubbles {
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
}
.bubble {
    position: absolute;
    z-index: -1;
    bottom: -100px;
    width: 40px;
    height: 40px;
    background: #c9e9ff;
    border-radius: 50%;
    opacity: 0.5;
    animation: rise 12s infinite ease-in;
    border: 4px solid #c9e9ff;
    -moz-box-shadow: #ffffff 2px 1px 0px 0px inset;
    -webkit-box-shadow: #ffffff 2px 1px 0px 0px inset;
    box-shadow: #ffffff 2px 1px 0px 0px inset;
}
.bubble:nth-child(2) {
    width: 20px;
    height: 20px;
    left: 20%;
    animation-duration: 8s;
    animation-delay: 6s;
}
.bubble:nth-child(5) {
    width: 35px;
    height: 35px;
    left: 55%;
    animation-duration: 9s;
    animation-delay: 2s;
}
.bubble:nth-child(8) {
    width: 35px;
    height: 35px;
    left: 80%;
    animation-duration: 8s;
    animation-delay: 3s;
}
.bubble:nth-child(1) {
    width: 40px;
    height: 40px;
    left: 10%;
    animation-duration: 9s;
    animation-delay: 5s;
}
.bubble:nth-child(6) {
    width: 45px;
    height: 45px;
    left: 45%;
    animation-duration: 8s;
    animation-delay: 6s;
}
.bubble:nth-child(9) {
    width: 45px;
    height: 45px;
    left: 70%;
    animation-duration: 9s;
    animation-delay: 4s;
}
.bubble:nth-child(3) {
    width: 50px;
    height: 50px;
    left: 35%;
    animation-duration: 10s;
    animation-delay: 2s;
}
.bubble:nth-child(4) {
    width: 80px;
    height: 80px;
    left: 50%;
    animation-duration: 12s;
    animation-delay: 0s;
}
.bubble:nth-child(7) {
    width: 90px;
    height: 90px;
    left: 70%;
    animation-duration: 13s;
    animation-delay: 5s;
}
.bubble:nth-child(10) {
    width: 90px;
    height: 90px;
    left: 25%;
    animation-duration: 13s;
    animation-delay: 3s;
}

@keyframes rise {
    0% {
        bottom: -100px;
        transform: translateX(0);
    }
    50% {
        transform: translate(100px);
    }
    100% {
        bottom: 1080px;
        transform: translateX(-200px);
    }
}
