/* Loading bubbles animation */
.loader-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.loader-logo {
    height: 75px;
    width: auto;
}
.loader-spring {
    position: relative;
    margin-left: 100px;
    margin-bottom: 15px;
}
/* background: rgba(201, 233, 255, 0.4); */
.loader-bubble {
    background: rgba(16, 200, 226, 0.4);
    width: 24px;
    height: 24px;
    position: absolute;
    display: block;
    left: 0;
    margin-left: -12px;
    bottom: 0;
    opacity: 0;
    border-radius: 50%;
    animation: bubbleUp 1s infinite ease-in-out;
    -webkit-animation: bubbleUp 1s infinite ease-in-out;
    -o-animation: bubbleUp 1s infinite ease-in-out;
    -moz-animation: bubbleUp 1s infinite ease-in-out;
}

.loader-bubble1 {
    left: 0px;
    top: 0px;
    -webkit-animation-delay: 0;
    /* Chrome, Safari, Opera */

    animation-delay: 0;
}

.loader-bubble2 {
    width: 16px;
    height: 16px;
    left: 0px;
    -webkit-animation-delay: 300ms;
    /* Chrome, Safari, Opera */

    animation-delay: 300ms;
}

.loader-bubble3 {
    left: -10px;
    top: -10px;
    -webkit-animation-delay: 600ms;
    /* Chrome, Safari, Opera */

    animation-delay: 600ms;
}

.loader-bubble4 {
    width: 28px;
    height: 28px;
    left: 5px;
    -webkit-animation-delay: 900ms;
    /* Chrome, Safari, Opera */

    animation-delay: 900ms;
    -webkit-animation-duration: 1.5s;
    /* Chrome, Safari, Opera */

    animation-duration: 1.5s;
}

.loader-bubble5 {
    width: 20px;
    height: 20px;
    left: 0px;
    top: 0px;
    -webkit-animation-delay: 1200ms;
    /* Chrome, Safari, Opera */

    animation-delay: 1200ms;
    -webkit-animation-duration: 1.2s;
    /* Chrome, Safari, Opera */

    animation-duration: 1.2s;
}

@keyframes bubbleUp3d {
    0% {
        -webkit-transform: translateY(0px) scale3d(0.5, 0.5, 0.5);
        opacity: 0;
    }
    30% {
        -webkit-transform: translateY(0px) scale3d(0.8, 0.8, 0.8);
        opacity: 1;
    }
    90% {
        -webkit-transform: translateY(-20px) scale3d(1, 1, 1);
        opacity: 1;
    }
    99% {
        -webkit-transform: translateY(-30px) scale3d(2, 2, 2);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0px) scale3d(1, 1, 1);
        opacity: 0;
    }
}

@keyframes bubbleUp {
    0% {
        top: 0px;
        -webkit-transform: scale(0.5);
        opacity: 0;
    }
    20% {
        top: 0px;
        -webkit-transform: scale(0.7);
        opacity: 1;
    }
    93% {
        top: -30px;
        -webkit-transform: scale(1);
        opacity: 1;
    }
    99% {
        top: -36px;
        -webkit-transform: scale(2);
        opacity: 0;
    }
    100% {
        top: 0px;
        -webkit-transform: scale(1);
        opacity: 0;
    }
}
