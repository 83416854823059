.logo-section {
    display: flex;
    align-items: center;
}

.logo {
    height: 35px;
    width: auto;
}

.language-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.language {
    cursor: pointer;
}

.language.active {
    text-decoration: underline;
    text-underline-offset: 2px;
}

hr.rounded {
    border-top: 8px solid #bbb;
    border-radius: 5px;
    margin: 10px 0 10px 0;
}
