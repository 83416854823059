.social-icons-container {
    gap: 15px;
    display: flex;
}
.social-icons-mobile-container {
    gap: 15px;
    display: flex;
    justify-content: center;
}
.social-icons {
    width: 30px;
}

.top-shadow-sm {
    box-shadow: 0 -0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.keywords {
    color: #f7f7f7;
    font-size: 10px;
    margin-top: 20px;
}
